import './App.css';
import Footer from './components/footer/footer.component';
import Header from './components/header/header.component';
import Main001 from './components/mainbody-001/mainbody.component-001';
import Main002 from './components/mainbody-002/mainbody.component-002';
import Main0924 from './components/mainbody-0924/mainbody.component-0924';
import Main001135 from './components/mainbody-001135/mainbody.component-001135';
import Main001287 from './components/mainbody-001287/mainbody.component-001287';
import Main001374 from './components/mainbody-001374/mainbody.component-001374';
import Main001498 from './components/mainbody-001498/mainbody.component-001498';
import Main02145 from './components/mainbody-02145/mainbody.component-02145';
import Main from './components/mainbody/mainbody.component';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import DoNotSell from './components/doNotSell/DoNotSell';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermsOfUse from './components/terms/TermsOfUse';
import Disclaimer from './components/disclaimer/Disclaimer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { Switch } from '@material-ui/core';


function App() {
  return (
    <div className="App">
       <Router basename="/">
       <Header />
        <Routes>
        {/* <Route path="/" element={<Navigate to="/-001" />}/> */}
          <Route path="/" element={<Main />} />
          <Route path="/-001" element={<Main001 />} />
          <Route path="/-002" element={<Main002 />} />          
          <Route path="/-0924" element={<Main0924 />} />
          <Route path="/-001135" element={<Main001135 />} />
          <Route path="/-001287" element={<Main001287 />} />
          <Route path="/-001374" element={<Main001374 />} />
          <Route path="/-001498" element={<Main001498 />} />
          <Route path="/-02145" element={<Main02145 />} />
          <Route path="/do-not-sell" element={<DoNotSell />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </Router>
    </div>
  );
}

export default App;
