import React, { useMemo } from 'react';
import './OtherBlogs.css';

const OtherBlogs = () => {
  const blogsData = useMemo(() => [
    {
      id: 1,
      title: 'Heart Surgeon Finds Natural Energy "Fix"?',
      image: '../assets/gundry-surge.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/9F3647/',
      category: 'Health Update | Gundry MD'
    },
    {
      id: 2,
      title: 'Watch This Vacuum Destroying a Water Jug. Top Choice of 2024',
      image: '../assets/ezvac_60_off.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/686M1C/',
      category: 'EZVac Pro'
    },
    {
      id: 3,
      title: 'This Oddball Food Reverses Memory Loss >>',
      image: '../assets/brain-potato.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/6XM19T/',
      category: 'Are You Having Senior Moments?'
    },
    {
      id: 4,
      title: 'This Light Bulb Turned into a Secret Security Camera',
      image: '../assets/lightsocketsecurity_camera.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/66RQ8Q/',
      category: 'Light Socket Security Cam'
    },
    {
      id: 5,
      title: 'Certified ENT: Do This At Home Routine To Stop Snorning For...',
      image: '../assets/zquiet2.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/B5WF5B/',
      category: 'Revolutionary Breakthrough'
    },
    {
      id: 6,
      title: 'Body Aches and Pains Relieved With Japanese Technique',
      image: '../assets/rest-nex-small.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/225JFQ/',
      category: 'Rest-Nex (EST)'
    },
    {
      id: 7,
      title: 'Need Relief from Shortness of Breath? Do This Immediately',
      image: '../assets/airphysio1.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/B799TX/',
      category: 'FDA Class Two Medical Devices | Global'
    },
    {
      id: 8,
      title: 'Local: Get Massive Discounts from Roofing Contractors',
      image: '../assets/homeprojectpro2.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/8CWLMG/',
      category: 'Free Quotes | Competiting Contractors'
    },
    {
      id: 9,
      title: 'Try Dr. Rodier`s 7-second joint-repair hack before bed…',
      image: '../assets/triflexin-joint.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/72DQR3/',
      category: 'Health Update | Dr. Rodier'
    },
    {
      id: 10,
      title: '',
      image: '../assets/rest-nex-small.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/225JFQ/',
      category: 'Rest-Nex (EST)'
    },
    {
      id: 11,
      title: 'MUST READ: 17 Savings Every Senior Can Take Back in 2024',
      image: '../assets/seniorstakeback2.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/L6TJCN/',
      category: 'Senior Savings Guide | Affordable'
    },
    {
      id: 12,
      title: 'Body Aches and Pains Relieved With Japanese Technique',
      image: '../assets/rest-nex-small.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/225JFQ/',
      category: 'Rest-Nex (EST)'
    },
  ], []);

  const location = window.location;

  // INPUT ID NUMBER OF BLOG POST YOU WANT TO DISPLAY
//  const blogIdsToDisplay = useMemo(() => [1, 2, 3, 4, 5, 6], []);
  const blogIdsToDisplay = useMemo(() => [1, 3, 5, 7, 11, 8], []);

    // Filter blogs and add URL parameters (without using state for the filtered blogs)
  const filteredBlogs = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    // Map over blogIdsToDisplay to ensure order is followed
    return blogIdsToDisplay.map(id => {
      const blog = blogsData.find(blog => blog.id === id);
      if (blog) {
        const urlWithParams = `${blog.url}?${searchParams.toString()}`;
        return { ...blog, urlWithParams };
      }
      return null;
    }).filter(blog => blog !== null);  // Remove any null values
  }, [blogsData, location.search, blogIdsToDisplay]);

  return (
    <div className="other-blogs">
      {filteredBlogs.map((blog) => (
        <div key={blog.id} className="blog-item">
          <a href={blog.urlWithParams} target="_blank" rel="noopener noreferrer">
            <img src={blog.image} alt={blog.title} />
            <p>{blog.title}</p>
            <p className='category'>{blog.category}</p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default OtherBlogs;
